import { getStorageItem } from '@helpers/localStorage';
import { LocalStorageKeysEnum } from '@enums/localStorageKeys.enum';
import { httpClient } from '@okta/axiosInstance';
import api from '@configs/urls/api';

export const deleteNotificationToken = async (): Promise<void> => {
  const token: Record<string, string> | undefined = getStorageItem(LocalStorageKeysEnum.PUSH_NOTIFICATION_TOKEN);

  try {
    await httpClient.delete({
      url: api.deleteFireBaseToken,
      config: {
        data: {
          firebaseDeviceToken: token ? token[LocalStorageKeysEnum.PUSH_NOTIFICATION_TOKEN] : '',

        },
      },
    });
  } catch {
  }
};
