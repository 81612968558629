import { format, addMinutes, intervalToDuration, parse } from 'date-fns';
import { format as tzFormat, utcToZonedTime } from 'date-fns-tz';

import { ITimeParts } from '@components/nativeTimePiker/interfaces/ITimeParts.interface';
import { DayPeriodEnum } from '@enums/dayPeriod.enum';
import { dateFormatFromBE } from '@configs/dateFormats.const';

interface ITimeConvertorOptions {
  isNewDate?: boolean;
}

export const getLocalTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const getTimeParts = (timeString: string): ITimeParts => {
  const currentDate = format(new Date(), 'yyyy-MM-dd');
  const dateWithTime = `${currentDate}T${timeString}`;

  const hours = format(new Date(dateWithTime), 'hh');
  const minutes = format(new Date(dateWithTime), 'mm');
  const dayPeriod: DayPeriodEnum = format(new Date(dateWithTime), 'aa') as DayPeriodEnum;

  return {
    hours,
    minutes,
    dayPeriod,
  };
};

const getTimeStringFromParts = (timeParts: ITimeParts): string => {
  const { hours, minutes, dayPeriod } = timeParts;
  const formattedHours = dayPeriod === DayPeriodEnum.AM ? hours : Number(hours) + 12;

  return `${formattedHours}:${minutes}:00`;
};

const convertTimePartsIntoTimeString = (timeParts: ITimeParts): string => {
  const { hours, minutes, dayPeriod } = timeParts;
  const formattedTime = `${hours}:${minutes} ${dayPeriod}`;
  const parsedTime = parse(formattedTime, 'h:mm a', new Date());

  return format(parsedTime, 'HH:mm:ss');
};

const getFormattedTime = (timeString: string): string => {
  const { hours, minutes, dayPeriod } = getTimeParts(timeString);

  return `${hours}:${minutes} ${dayPeriod}`;
};

const remainingTime = (time: number): string => {
  return format(addMinutes(new Date(), time), 'yyyy-MM-dd\'T\'HH:mm:ss');
};

const convertUTCToLocalTimeStamp = (date: string | number, options?: ITimeConvertorOptions): string => {
  const { isNewDate } = options || {};
  const currentDate = isNewDate ? date : `${date}Z`;
  return format(new Date(currentDate), 'yyyy-MM-dd\'T\'HH:mm:ss');
};

const formatDuration = (createdDate: string): string => {
  const formattedTime: Record<string, number> = intervalToDuration(
    {
      start: new Date(),
      end: new Date(createdDate),
    },
  );

  return Object.keys(formattedTime)
    .slice(0, 5)
    .filter((value: string) => formattedTime[value] !== 0)
    .map((value: string): string => `${formattedTime[value]}${value.charAt(0)}`)
    .join(' ');
};

const convertDateLocalTime = (value: string): Date => {
  return new Date(`${value} 00:00`);
};

const convertDateLocalTimeString = (value: string): string => {
  return `${value} 00:00`;
};

const getLocalTimeZoneFormat = (): string => {
  const utcZonedTime = utcToZonedTime(new Date(), getLocalTimeZone());

  return tzFormat(utcZonedTime, 'XXX', { timeZone: getLocalTimeZone() });
};

const getDateWithZoneFormat = (date: string): Date => {
  return new Date(`${date}T00:00:00${getLocalTimeZoneFormat()}`);
};

const reformattedDate = (date?: string | null, formatString = 'MM/dd/yyyy'): string | null => {
  if (!date) return null;
  const formattedDate = date.replace(/-/g, '/');

  return format(convertDateLocalTime(formattedDate), formatString);
};

const formatCurrentTime = (
  timeString: string,
  currentFormat?: string,
): string => {
  return format(
    timeString ? new Date(timeString) : new Date(),
    currentFormat || dateFormatFromBE,
  );
};

export {
  getTimeParts,
  remainingTime,
  formatDuration,
  getFormattedTime,
  getTimeStringFromParts,
  convertUTCToLocalTimeStamp,
  convertDateLocalTimeString,
  convertDateLocalTime,
  convertTimePartsIntoTimeString,
  getDateWithZoneFormat,
  formatCurrentTime,
  reformattedDate,
};
