import { createContext, FC, PropsWithChildren, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useFeatureFlags } from '@providers/featureFlagsProvider';
import { emailDomainChecker } from '@helpers/emailDomainChecker';
import { useChurnZero } from '@hooks/useChurnZero/useChurnZero';
import { fFlagsNames } from '@configs/fFlagsNames/fFlagsNames';
import ExperienceSwitcher from '@features/experienceSwitcher';
import { changeLanguage } from '@helpers/changeLanguage';
import { ELocalization } from '@enums/localization.enum';
import eventBus from '@eventBus/index';
import { useOkta } from '@features/oktaClient';
import { notifications, NotificationTypeEnum } from '@helpers/notificationsMessages';
import {
  IPushNotification,
} from '@features/sharedWidgets/pushNotificationsProvider/interfaces/pushNotification.interface';
import {
  EUserInfoActionsType,
} from '@features/navigationContainer/components/pushNotificationMenu/enums/notificationStatusType.enum';
import { isSomeExist } from '@helpers/arrayUtils';
import { useKeyboardShortcut } from '@hooks/useKeyboardShortcut/useKeyboardShortcut';
import { RolesEnum } from '@enums/tenantRoles.enum';

import { TSharedTenantData } from './interfaces/tenantProvider.interface';
import { denyEmailDomains } from './tenantProvider.const';
import { useGetTenant } from './hooks/useGetTenant/useGetTenant';

export const TenantContext = createContext<TSharedTenantData | undefined>(undefined);

const TenantProvider: FC<PropsWithChildren> = ({ children }) => {
  const { logout, getAccessTokenSilently, getTokenData } = useOkta();
  const { enqueueSnackbar } = useSnackbar();
  const { isUnderFeatureFlag } = useFeatureFlags();
  const { initChurnZero, stopTrackEvent, trackEvent } = useChurnZero();
  const {
    tenant,
    error,
    setTenant,
    getTenantHandler,
    getTenantSilentHandler,
  } = useGetTenant();
  const { t } = useTranslation('informationMessages');

  const copyToken = (): void => {
    const token = getTokenData();
    navigator.clipboard.writeText(token || 'Token is empty');
  };

  useKeyboardShortcut({
    shortcutKeys: ['Shift', 'k', 'j'],
    callback: copyToken,
    options: {
      ignoreInputFields: false,
      repeatOnHold: false,
    },
  });

  const materialProducerNotificationHandler = async (): Promise<void> => {
    await logout();
    enqueueSnackbar(
      t(notifications.blockMaterialProducer.body),
      { variant: NotificationTypeEnum.error },
    );
  };

  const updateCurrentTenant = async (): Promise<void> => {
    if (!tenant) return;
    try {
      await getAccessTokenSilently();
      const response = await getTenantSilentHandler();
      if (!response) return;
      const isSome = isSomeExist(tenant.roles, response.roles);
      if (response.materialProducerId !== tenant.materialProducerId || !isSome) {
        window.location.reload();
      }

      setTenant(response);
    } catch {
    }
  };

  const userInfoNotificationHandler = async (notification: IPushNotification): Promise<void> => {
    if (notification.action === EUserInfoActionsType.BLOCK || notification.action === EUserInfoActionsType.ARCHIVE) {
      await logout();
      enqueueSnackbar(
        t(notifications.blockUser.body),
        { variant: NotificationTypeEnum.error },
      );
    }
    if (notification.action === EUserInfoActionsType.MODIFY) {
      updateCurrentTenant();
    }
  };

  useEffect(() => {
    if (!tenant) return;
    const {
      materialProducerId,
      email,
      materialProducerName,
      language,
    } = tenant;

    if (!email || emailDomainChecker(email, denyEmailDomains)) return;

    if (isUnderFeatureFlag(fFlagsNames.fflagLocalizationWithBE)) {
      changeLanguage(ELocalization[language]);
    }

    initChurnZero(materialProducerId, email, materialProducerName);
  }, [tenant]);

  useEffect(() => {
    const unsubscribe = eventBus.materialProducerNotification.subscribe(materialProducerNotificationHandler);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = eventBus.userInfoNotification.subscribe(userInfoNotificationHandler);
    return () => unsubscribe();
  }, [tenant]);

  const providerValue = {
    value: tenant,
    error,
    stopTrackEvent,
    trackEvent,
    getTenantHandler,
    setTenant,
  };

  return (
    <TenantContext.Provider value={providerValue}>
      {tenant?.experience === null && !tenant.roles.includes(RolesEnum.IT_ADMIN_ONLY)
        ? <ExperienceSwitcher />
        : children
      }
    </TenantContext.Provider>
  );
};

export { TenantProvider };
