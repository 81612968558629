import { makeStyles } from '@theme/appTheme';

const useGlobalTypographyStyles = makeStyles()(() => ({
  textFormat: {
    textTransform: 'lowercase',
    '&:first-line': {
      textTransform: 'capitalize',
    },
  },
  cutDownString: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  breakWord: {
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  textAlignLeft: {
    textAlign: 'start',
  },
  bold: {
    fontWeight: 600,
  }
}));

export default useGlobalTypographyStyles;
